import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Title from './Title';

const MainComponent = () => {
    return (
        <div className="MainComponent">
            <Header/>
            <Title/>
            <Footer/>
        </div>
    );
};

export default MainComponent;