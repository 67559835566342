import React from 'react';
 
import './Header.scss'

const Header = () => {
    return (
        <div className="Header">
            <div className="Header_nav">
                <a href="mailto:info@buildingsliving.com">Contact</a>
            </div>
        </div>
    );
};

export default Header;