import React from 'react';

import './Title.scss'

const Title = () => {
    return (
        <div className="Title">
            <h1>Shades</h1>
        </div>
    );
};

export default Title;